export default {
    Dashboards: 'Панель',
    CRM: 'CRM',
    Analytics: 'Аналітика',
    eCommerce: 'Електронна комерція',
    Calendar: 'Календар',
    Chat: 'Чат',
    Email: 'Пошта',
    Invoice: 'Facturer',
    List: 'Lister',
    Preview: 'Aperçu',
    Edit: 'Редагувати',
    Add: 'Додати',
    User: 'Користувач',
    'User List': "liste d'utilisateur",
    'User View': 'Vue utilisateur',
    'APPS AND PAGES': 'APPLICATIONS ET PAGES',
    Pages: 'Сторінкі',
    Authentication: 'Authentification',
    'Login V1': 'Connexion V1',
    'Login V2': 'Connexion V2',
    'Register V1': "S'inscrire V1",
    'Register V2': "S'inscrire V2",
    'Forgot Password V1': 'Mot de passe oublié V1',
    'Forgot Password V2': 'Mot de passe oublié V2',
    'Reset Password V1': 'Réinitialiser le mot de passe V1',
    'Reset Password V2': 'Réinitialiser le mot de passe V2',
    Miscellaneous: 'Divers',
    'Under Maintenance': 'En maintenance',
    Error: 'Помилка',
    'Coming Soon': 'Bientôt disponible',
    'Not Authorized': 'Pas autorisé',
    'Knowledge Base': 'Base de connaissances',
    'Account Settings': 'Paramètres du compte',
    Pricing: 'Prix',
    FAQ: 'FAQ',
    'USER INTERFACE': 'INTERFACE UTILISATEUR',
    Typography: 'Typographie',
    Icons: 'Icônes',
    Gamification: 'Gamification',
    Cards: 'Cartes',
    Basic: 'De base',
    Statistics: 'Statistiques',
    Advance: 'Avance',
    Actions: 'Actions',
    Chart: 'Graphique',
    Components: 'Composants',
    Alert: 'Alerte',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Button: 'Bouton',
    Menu: 'Меню',
    'Expansion Panel': "Panneau d'extension",
    Dialog: 'Dialogue',
    Pagination: 'Pagination',
    Tabs: 'Onglets',
    Tooltip: 'Info-bulle',
    Chip: 'Ébrécher',
    Snackbar: 'Snack-bar',
    Stepper: 'Pas à pas',
    Timeline: 'Chronologie',
    Treeview: 'Arborescence',
    'FORMS & TABLES': 'FORMULAIRES & TABLEAUX',
    'Forms Elements': 'Éléments de formulaires',
    Autocomplete: 'Saisie automatique',
    Checkbox: 'Case à cocher',
    Combobox: 'Boîte combo',
    'File Input': 'Entrée de fichier',
    Radio: 'Radio',
    'Range Slider': 'Curseur de plage',
    Select: 'Sélectionner',
    Slider: 'Glissière',
    Switch: 'Changer',
    Textarea: 'Zone de texte',
    Textfield: 'Champ de texte',
    'Date Picker': 'Sélecteur de date',
    'Month Picker': 'Sélecteur de mois',
    'Time Picker': 'Sélecteur de temps',
    Rating: 'Notation',
    'Form Layout': 'Disposition du formulaire',
    'Form validation': 'Validation du formulaire',
    Tables: 'les tables',
    'Simple Table': 'Tableau simple',
    Datatable: 'Table de données',
    'CHARTS AND MAPS': 'CARTES ET CARTES',
    Charts: 'Graphiques',
    'Apex Chart': 'Graphique Apex',
    Chartjs: 'Chartjs',
    'Leaflet Maps': 'Dépliant Cartes',
    Others: 'Autres',
    'Menu Levels': 'Niveaux de menu',
    'Menu Level 2.1': 'Niveau de menu 2.1',
    'Menu Level 2.2': 'Niveau de menu 2.2',
    'Menu Level 3.1': 'Menu Niveau 3.1',
    'Menu Level 3.2': 'Niveau de menu 3.2',
    'Disabled Menu': 'Menu désactivé',
    'Raise Support': 'Augmenter le soutien',
    Documentation: 'Documentation',
    'Charts & Maps': 'Graphiques et cartes',
    Apps: 'applications',
    'User Interface': 'Interface utilisateur',
    'Forms & Tables': 'Formulaires et tableaux',
    Inventory: "Інвентар",
    All: "Усі",
    Published: "Опублікованні",
    Unpublished: "Неопублікованні",
    Uploaded: "Завантаженні",
    Create: "Створити",
    Upload: "Завантажити",
    'Upload File': "Завантажити файл",
    'Download the document for fast loading of goods.': "Завантажити документ для швидкого завантаження.",
    'document template.': "зразок документа.",
    'Download': "Завантажити",
    'Supported file formats: CSV, XLS, XLSX.': "Дозволенні формати файлів: CSV, XLS, XLSX.",
    'File to upload': "Завантажити файл",
    'Status': "Статус",
    'No file chosen': "Файл не обрано",
    'Total Products': "Кількість товару",
    Verified: "Перевіренно",
    Unverified: "Неперевіренно",
    'Rejected / Banned': "Відхилений / Бан",
    Draft: 'Чернетка',
    Delete: "Видалити",
    Details: "Подробиці",
    Publish: "Опублікувати",
    'All Products': "Усі товари",
    Orders: "Замовлення",
    'All Orders': "Усі замовлення",
    Refunds: "Повернення",
    Account: "Аккаунт",
    Profile: "Профіль",
    Security: "Безпека",
    Users: "Користувачі",
    'All users': "Усі користувачі",
    Buyers: "Покупці",
    Sellers: "Продавці",
    Moderators: "Модератори",
    Logout: "Вийти",
    'buy now': "Придбати",
    'add to card': "Додати у кошик",
    Date: 'Дата',
    'Product details': "Інформація про продукт",
    Reject: 'Відхилити',
    Verify: 'Підтвердити',
    Ban: 'Бан',
    'Description:': 'Опис:',
    'Date:': 'Дата:',
    'Sold Times:': 'Продано раз:',
    'Status:': 'Статус:',
    Payouts: 'Виплати',
    Paid: 'Оплачені',
    Pending: 'Очікуються',
    Transactions: "Транзакції",
    Balance: "Баланс",
    'go to storefront': 'перейти до вітрини',
    'Update existing products': "Оновити наявні продукти",
    'Choose file': 'Обрати файл',
    'Purchases can only be refunded for Deal Credits that are directly transferred to your Deal Wallet. Cash refund not supported.': 'Покупки можна відшкодувати лише за кредити угоди, які безпосередньо перераховано на ваш гаманець угоди. Відшкодування готівкою не підтримується.',
    Unpublish: 'Не публікувати',
    'Total Users': 'Усього користувачів',
    Billing: "Оплати",
    'Search ...': 'Пошук ...',
    Role: 'Роль',
    'Date of registration': 'Дата реєстрації',
    'Buyer\’s balance': 'Баланс покупця',
    Store: 'Магазин',
    'Edit user account': 'Редагувати аккаунт користувача',
    'Full name': 'Повне ім\'я',
    Close: 'Закрити',
    Save: 'Зберегти',
    'Upload new photo': 'Завантажити нове фото',
    'Allowed JPG, GIF or PNG. Max size of 800K': 'Дозволенні JPG, GIF або PNG. Макс. розмір до 800K',
    Address: 'Адреса',
    'Vendor\’s balance': 'Баланс продавця',
    Company: 'Компанія',
    Phone: 'Телефон',
    Country: 'Країна',
    'Post Code': 'Поштовий індекс',
    'Billing address': 'Платіжна адреса',
    'Edit store': 'Редагувати магазин',
    Description: 'Опис',
    Created: 'Створенний',
    'Store title': 'Ім\'я магазину',
    'Upload store logo': 'Завантажити логотип магазину',
    'Upload new banner': 'Завантажити новий банер',
    'We carefully select sellers who can sell on the platform. Please describe your work experience and let us know how many products you plan to sell. You will be notified by email when we approve your application.': 'Ми ретельно вибираємо продавців, які можуть продавати на платформі. Будь ласка, опишіть свій досвід роботи та повідомте нам, скільки продуктів ви плануєте продавати. Ви будете повідомлені електронною поштою, коли ми схвалимо вашу заявку.',
    'Upgrade account to Vendor': 'Оновити аккаунт до продавця',
    'update account': 'оновити аккаунт',
    'Update Account': 'Оновити Аккаунт',
    'The user will have access to the selected sections, pages.': 'Користувачеві будуть доступні вибрані розділи, сторінки.',
    'Permissions': 'Дозволи'
  }
  